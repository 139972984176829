<template>
	<div class="box">
		<div class="fotnav">
			<div class="top" v-if="false">
				<div class="left">
					<div class="lleft">
						<div class="linklist">
							<span>友情链接：</span>
							<div v-for="(item,index) in linkList" class="linkclass">
								<a :href="item.url">{{item.name}}</a>
							</div>
						</div>
						<span style="font-size: 24px;">威海神舟中小企业公共服务平台</span>
						<span class="pingtai">—— 国家级中小企业公共服务示范平台 ——</span>
					</div>
					<div class="center"></div>
					<div class="lright">
						<img class="aboutjpg" src="../assets/images/about.jpg" />
					</div>
				</div>
				<div class="left">
					<div class="lleft">
						<span style="font-size: 14px;">威海神舟中小企业公共服务中心</span>
						<span style="font-size: 12px;">单位地址：山东省威海市火炬高技术产业开发区火炬路213号创新创业基地A座18楼1803</span>
						<span style="font-size: 12px;">联系电话：0631-5262611 &nbsp; &nbsp;&nbsp;单位邮箱：uweihai@126.com</span>
					</div>
					<!-- <div class="center"></div>
					<div class="lright">
						<span>sdsadas@gfgdf.org.cn</span>
						<span>0086-455-747878</span>
						<span>0086-4545-454541558号</span>
					</div> -->
				</div>


			</div>
			<div class="bot">
				<div class="smallbar">
					<router-link @click.native="closeScreen"  target="_blank" to="/">首页</router-link><span class="smallline"></span>
					<router-link @click.native="closeScreen"  target="_blank" to="/demandNews">培训发布</router-link><span class="smallline"></span>
          <router-link @click.native="closeScreen"  target="_blank" to="/supplyNews">我要培训</router-link><span class="smallline"></span>
					<router-link @click.native="closeScreen"  target="_blank" to="/about">关于我们</router-link><span></span>
					<!-- <div>联系方式</div><span class="smallline"></span>
					<div>法律声明</div><span class="smallline"></span>
					<router-link @click.native="closeScreen"  target="_blank" to="/">服务条款</router-link><span class="smallline"></span>
					<router-link @click.native="closeScreen"  target="_blank" to="/yinsi">隐私政策</router-link> -->
				</div>

				<div class="botnav">
					Copyright © 威海神舟信息技术研究院 All Rights Reserved
					<img src="../assets/images/jinghui.png" />
					<a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo">
						鲁ICP备2022018824号-1</a>
					<!-- <img src="../assets/images/police.png" /> -->
					<!-- <div>鲁ICP备17052774号-1</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				linkList: ''
			}
		},
		mounted() {
			this.getLinkList()
			setTimeout(function() {
				new Swiper('.urlSwiper', {
					slidesPerView: 3,
					observer: true,
					observeParents: true,
				})
			}, 300)
		},
		methods: {
			//获取外链
			getLinkList() {
				this.$apiFun.linkList({
					type: 2
				}).then((res) => {
					this.linkList = res.data
					console.log(res)
				})
			},
		}
	}
</script>

<style scoped>
	.linklist {
		display: flex;
		font-size: 12px;
	}
	.linkclass{
		font-size: 12px;
	}
	.linkclass{
		margin-left: 15px;
	}
	.smallbar {
		display: flex;
		font-size: 13px;
	}

	.smallline {
		border-left: 1px solid white;
		width: 12px;
		margin-left: 10px;
		height: 10px;
		margin-top: 4px;
	}

	.aboutjpg {
		width: 102px;
		height: 102px;
	}

	.footcenter {
		width: 160px;
	}

	.linkFoot {
		width: 600px;
	}

	.foottwo {
		font-size: 12px;
		text-align: center;
	}

	.footone {
		font-size: 16px;
		letter-spacing: 2px;
		text-align: center;
	}

	.contact {
		cursor: pointer;
	}

	.footwiht {
		width: 1px;
		height: 13px;
		background-color: #fff;
		margin: 0 16px;
	}

	.foothrf {
		display: flex;
		align-items: center;
	}

	.footdhyd,
	.dootem {
		display: flex;
		align-items: center;
	}

	.lright a {
		cursor: pointer;
	}

	.footuel a:hover {
		color: #3389FF;
		/* font-size: 13px; */
	}

	.footuel a {
		cursor: pointer;
		/* margin-left: 15px; */
	}

	.footuel {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	.footcenter img {
		width: 104px;
		height: 104px;
	}

	.bot {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		/* padding-top: 27px; */
		height: 73px;
	}

	.botnav img {
		width: 18px;
		height: 18px;
		margin: 0 4px 0 4px;
	}

	.botnav {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #FFFFFF;
	}

	.lcenter {
		width: 1px;
		height: 80px;
		background-color: #FFF;
		margin: 0 52px 0 46px;
	}

	.lleft,
	.lright {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 90px;
	}

	.left,
	.right {
		display: flex;
		align-items: center;
		/* justify-content: space-around; */
		font-size: 12px;
	}

	.top {
		width: 1200px;
		height: 130px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		border-bottom: 1px solid #fff;
	}

	.fotnav {
		width: 1200px;
		margin: auto;
	}

	.box {
		width: 100%;
		max-width: 1920px;
		margin: auto;
		/* background: #1D2735; */
		background: #555555;
		box-sizing: border-box;
		color: #fff;
    padding: 30px;

	}

	.left .center {
		height: 80px;
		margin: 0 52px;
	}
</style>
